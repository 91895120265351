import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {createStore} from "redux";
import firebase from "firebase/app";
import "firebase/database";
import rootReducer from "./reducers";
import "./styles/index.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";

const store = createStore(
  rootReducer,
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const rrfConfig = {
  userProfile: "users",
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance // <- needed if using firestore
};

const config = {
  //apiKey: "apiKey",
  //authDomain: "projectId.firebaseapp.com",
  databaseURL: "https://fir-test-2-2cc55.firebaseio.com/",
  //storageBucket: "bucket.appspot.com"
};

firebase.initializeApp(config);

// // Get a reference to the database service
// const database = firebase.database();
// const pagedoc = database.ref('testroom/');
//
// pagedoc.on('value', (dataSnapshot) => {
//   const val = dataSnapshot.val();
//   if (val === null) {
//     pagedoc.set(initialState);
//   } else {
//     store.dispatch(updateDocument(val));
//   }
// });

// store.subscribe(() => {
//   pagedoc.set(store.getState());
// });

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
