import React from "react";
import {Link} from "react-router-dom";

const NotFound = () => (
  <div>
    <section className="hero is-light is-bold is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-2">Page Not Found</h1>
          <div className="content">
            <p>
              Sorry; the page you were looking for was not found.
              <br />
              <br />
              <Link className="button" to="/">
                Go to Home Page
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default NotFound;
