import React from "react";
import {Helmet} from "react-helmet";

import ItemsTable from "../ItemsTable";
import EditableMarkdownArea from "../EditableMarkdownArea";

function Sheet({sheet, sheetId, loaded, onSheetUpdate}) {
  const defaultSheetAttribute = (attr, defaultValue) =>
    (sheet && sheet[attr]) || defaultValue;

  const handleTitleChange = e => {
    onSheetUpdate({title: e.target.value});
  };

  const handleDescriptionChange = text => {
    onSheetUpdate({description: text});
  };

  const handleUnitsChange = units => {
    onSheetUpdate({units});
  };

  const handleConfidenceChange = confidenceLevel => {
    onSheetUpdate({confidenceLevel});
  };

  return (
    <div className="Page">
      {!loaded && (
        <div className="container">
          <section className="section">
            <div>
              <i className="fas fa-spinner fa-pulse" />
              &nbsp;&nbsp;Loading...{" "}
            </div>
          </section>
        </div>
      )}

      {loaded && (
        <div>
          <Helmet>
            <title>{defaultSheetAttribute("title", "Untitled Sheet")}</title>
          </Helmet>
          <div className="container">
            <section className="section">
              <input
                className="input is-static title is-2 is-height-auto is-paddingless"
                type="text"
                value={defaultSheetAttribute("title", "Untitled Sheet")}
                onChange={handleTitleChange}
              />
              <EditableMarkdownArea
                text={defaultSheetAttribute(
                  "description",
                  "Create an informative description for your set of estimates here. Markdown is supported"
                )}
                onTextSaved={text => handleDescriptionChange(text)}
              />
              <ItemsTable
                sheetId={sheetId}
                units={defaultSheetAttribute("units", "days")}
                confidenceLevel={defaultSheetAttribute("confidenceLevel", "68PC")}
                onUnitsChange={handleUnitsChange}
                onConfidenceChange={handleConfidenceChange}
              />
            </section>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sheet;
