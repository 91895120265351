import React from "react";

const ItemDropDown = ({onMoveUp, onMoveDown, onDelete}) => {
  const handleMoveUpClick = e => {
    e.preventDefault();
    onMoveUp();
  };

  const handleMoveDownClick = e => {
    e.preventDefault();
    onMoveDown();
  };

  const handleDeleteClick = e => {
    e.preventDefault();
    onDelete();
  };

  return (
    <div className="dropdown is-hoverable">
      <div className="dropdown-trigger">
        <button
          tabIndex="-1"
          className="button is-small"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span className="icon is-small">
            <i className="fas fa-chevron-down" aria-hidden="true" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <a href="#" className="dropdown-item" onClick={handleMoveUpClick}>
            Move up
          </a>
          <a className="dropdown-item" onClick={handleMoveDownClick}>
            Move down
          </a>
          <hr className="dropdown-divider" />
          <a
            href="#"
            className="dropdown-item has-text-danger"
            onClick={handleDeleteClick}
          >
            Delete
          </a>
        </div>
      </div>
    </div>
  );
};

export default ItemDropDown;
