import React from "react";

import "./ItemsTable/ItemsTable.scss";

class ThrottledInput extends React.Component {
  constructor(props) {
    super(props);
    this.titleInput = React.createRef();
    this.state = {currentValue: props.defaultValue};
  }

  handleItemTitleChange(newTitle) {
    this.setState({currentValue: newTitle});
  }

  handleItemTitleBlur() {
    this.props.onChange(this.state.currentValue);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.defaultValue !== prevProps.defaultValue &&
      this.props.defaultValue !== this.state.currentValue
    ) {
      this.setState({currentValue: this.props.defaultValue});
    }
  }

  select() {
    this.titleInput.current.select();
  }

  render() {
    return (
      <input
        className="input is-static"
        type="text"
        ref={this.titleInput}
        value={this.state.currentValue}
        onChange={e => this.handleItemTitleChange(e.target.value)}
        onBlur={e => this.handleItemTitleBlur(e.target.value)}
      />
    );
  }
}

export default ThrottledInput;
