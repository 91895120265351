import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";

import {firebaseConnect, isLoaded} from "react-redux-firebase";

import Sheet from "./Sheet";

function mapStateToProps(state, ownProps) {
  const loaded = isLoaded(state.firebase.data.sheets);
  const sheetId = ownProps.match.params.sheetId;
  let sheet = {};
  const hasSheetData = loaded && state.firebase.data.sheets[sheetId];

  if (loaded && hasSheetData) {
    sheet = state.firebase.data.sheets[sheetId];
  }

  return {
    sheet,
    loaded,
  };
}

function SheetContainer({firebase, sheet, match, loaded}) {
  const sheetId = match.params.sheetId;

  const handleSheetUpdate = updateData => {
    console.log(updateData);
    firebase.ref(`sheets/${sheetId}`).update(updateData);
  };

  return (
    <Sheet
      sheet={sheet}
      sheetId={sheetId}
      loaded={loaded}
      onSheetUpdate={handleSheetUpdate}
    />
  );
}

export default compose(
  firebaseConnect(props => [`sheets/${props.match.params.sheetId}`]),
  connect(mapStateToProps)
)(SheetContainer);
