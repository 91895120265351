import React from "react";

import "../ItemsTable.scss";

import EditableMarkdownArea from "../../EditableMarkdownArea";
import ItemDropDown from "./ItemDropDown";
import ThrottledInput from "../../ThrottledInput";
import Estimates from "../../../models/estimates";

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.titleInput = React.createRef();
    this.state = {expanded: false};
  }

  componentDidMount() {
    this.titleInput.current.select();
  }

  standardDeviationMultiplier() {
    const standardDeviationMultipliers = {
      MEAN: 0,
      "68PC": 1,
      "90PC": 1.645,
      "95PC": 2,
    };

    return standardDeviationMultipliers[this.props.confidenceLevel];
  }

  calculatedMinEstimate() {
    return Estimates.confidenceInterval(
      this.props.item.estimates,
      this.standardDeviationMultiplier()
    )[0];
  }

  calculatedMaxEstimate() {
    return Estimates.confidenceInterval(
      this.props.item.estimates,
      this.standardDeviationMultiplier()
    )[1];
  }

  handleToggleExpandedClick() {
    this.setState({expanded: !this.state.expanded});
  }

  handleItemTitleBlur(newTitle) {
    this.props.onItemUpdate(this.props.item.id, {title: newTitle});
  }

  handleDescriptionTextSave(text) {
    this.props.onItemUpdate(this.props.item.id, {description: text});
  }

  handleEstimateChange(estimateType, value) {
    this.props.onEstimateUpdate(this.props.item.id, {[estimateType]: value});
  }

  handleItemDelete() {
    this.props.onItemDelete(this.props.item.id);
  }

  handleMoveUp() {
    this.props.onMoveUp(this.props.item.id);
  }

  handleMoveDown() {
    this.props.onMoveDown(this.props.item.id);
  }

  render() {
    return (
      <tr>
        <td>
          <span className="level is-marginless">
            <span className="level-left is-flex-grow">
              <span
                className="icon has-text-link is-pointable"
                onClick={() => this.handleToggleExpandedClick()}
              >
                <i
                  className={`fas fa-chevron-${this.state.expanded ? "down" : "right"}`}
                />
              </span>

              <ThrottledInput
                ref={this.titleInput}
                defaultValue={this.props.item.title}
                onChange={title => this.handleItemTitleBlur(title)}
              />
            </span>
            <span className="level-right">
              <ItemDropDown
                onMoveUp={() => this.handleMoveUp()}
                onMoveDown={() => this.handleMoveDown()}
                onDelete={() => this.handleItemDelete()}
              />
            </span>
          </span>
          {this.state.expanded && (
            <div className="box is-shadowless">
              <EditableMarkdownArea
                text={this.props.item.description}
                onTextSaved={text => this.handleDescriptionTextSave(text)}
              />
            </div>
          )}
        </td>
        <td className="estimates-table-small-column">
          <ThrottledInput
            defaultValue={this.props.item.estimates.best}
            onChange={value => this.handleEstimateChange("best", value)}
          />
        </td>
        <td className="estimates-table-small-column">
          <ThrottledInput
            defaultValue={this.props.item.estimates.likely}
            onChange={value => this.handleEstimateChange("likely", value)}
          />
        </td>
        <td className="estimates-table-small-column">
          <ThrottledInput
            defaultValue={this.props.item.estimates.worst}
            onChange={value => this.handleEstimateChange("worst", value)}
          />
        </td>
        <td className="estimates-table-small-column">
          <span>{this.calculatedMinEstimate().toFixed(1)}</span>
        </td>
        <td className="estimates-table-small-column">
          <span>{this.calculatedMaxEstimate().toFixed(1)}</span>
        </td>
      </tr>
    );
  }
}

export default Item;
