function sanitize(estimate) {
  const {best, likely, worst} = estimate;
  return {
    best: parseFloat(best),
    likely: parseFloat(likely),
    worst: parseFloat(worst),
  };
}

function mean(estimate) {
  const {best, likely, worst} = sanitize(estimate);
  return (best + 4 * likely + worst) / 6;
}

function standardDeviation(estimate) {
  const {best, worst} = sanitize(estimate);
  return (worst - best) / 6;
}

function variance(estimate) {
  return Math.pow(standardDeviation(estimate), 2);
}

function combinedMeans(estimates) {
  return estimates.reduce((combinedMean, estimate) => mean(estimate) + combinedMean, 0);
}

function combinedStandardDeviations(estimates) {
  const combinedVariance = estimates.reduce(
    (combinedVariance, estimate) => variance(estimate) + combinedVariance,
    0
  );
  return Math.sqrt(combinedVariance);
}

function totalEstimatesAtPoint(estimates, pointLevel) {
  return estimates.reduce((total, estimate) => total + sanitize(estimate)[pointLevel], 0);
}

function confidenceInterval(estimate, standardDeviationMultiplier) {
  return [
    mean(estimate) - standardDeviationMultiplier * standardDeviation(estimate),
    mean(estimate) + standardDeviationMultiplier * standardDeviation(estimate),
  ];
}

function totalCorrelatedConfidenceInterval(estimates, standardDeviationMultiplier) {
  const confidenceIntervals = estimates.map(estimate =>
    confidenceInterval(estimate, standardDeviationMultiplier)
  );
  return confidenceIntervals.reduce(
    ([totalMin, totalMax], [min, max]) => [totalMin + min, totalMax + max],
    [0, 0]
  );
}

function totalUncorrelatedConfidenceInterval(estimates, standardDeviationMultiplier) {
  return [
    combinedMeans(estimates) -
      standardDeviationMultiplier * combinedStandardDeviations(estimates),
    combinedMeans(estimates) +
      standardDeviationMultiplier * combinedStandardDeviations(estimates),
  ];
}

export default {
  mean,
  standardDeviation,
  variance,
  combinedMeans,
  combinedStandardDeviations,
  totalEstimatesAtPoint,
  confidenceInterval,
  totalCorrelatedConfidenceInterval,
  totalUncorrelatedConfidenceInterval,
};
