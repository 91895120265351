import React from "react";

import "bulma/css/bulma.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./App.css";

import Sheet from "./Sheet";
import NotFound from "./NotFound";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./Home";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/sheet/:sheetId" component={Sheet} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
