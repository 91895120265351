import React from "react";

const ConfidenceDropDown = ({confidenceLevel, onConfidenceChange}) => {
  const handleSelection = e => {
    e.preventDefault();
    const level = e.target.dataset.level;
    onConfidenceChange(level);
  };

  const displayNames = {
    MEAN: "Mean",
    "68PC": "68% Confidence",
    "90PC": "90% Confidence",
    "95PC": "95% Confidence",
  };

  return (
    <div className="dropdown is-hoverable">
      <div className="dropdown-trigger">
        <button
          className="button has-text-weight-bold"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span className="icon is-small">
            <i className="fas fa-chevron-down" aria-hidden="true" />
          </span>
          <span>{displayNames[confidenceLevel]}</span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <a
            data-level="MEAN"
            className={`dropdown-item ${confidenceLevel === "MEAN" ? "is-active" : ""}`}
            onClick={handleSelection}
          >
            {displayNames["MEAN"]}
          </a>
          <a
            data-level="68PC"
            className={`dropdown-item ${confidenceLevel === "68PC" ? "is-active" : ""}`}
            onClick={handleSelection}
          >
            {displayNames["68PC"]}
          </a>
          <a
            data-level="90PC"
            className={`dropdown-item ${confidenceLevel === "90PC" ? "is-active" : ""}`}
            onClick={handleSelection}
          >
            {displayNames["90PC"]}
          </a>
          <a
            data-level="95PC"
            className={`dropdown-item ${confidenceLevel === "95PC" ? "is-active" : ""}`}
            onClick={handleSelection}
          >
            {displayNames["95PC"]}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfidenceDropDown;
