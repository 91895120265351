import React from "react";

import "./ItemsTable.scss";
import Item from "./Item";
import UnitsEditButton from "./UnitsEditButton";
import ConfidenceDropDown from "./ConfidenceDropDown";
import Estimates from "../../models/estimates";

const ItemsTable = ({
  loaded,
  items,
  units,
  confidenceLevel,
  onItemAdd,
  onItemUpdate,
  onEstimateUpdate,
  onItemDelete,
  onMoveUp,
  onMoveDown,
  onUnitsChange,
  onConfidenceChange,
}) => {
  const handleItemAdd = () => {
    onItemAdd();
  };

  const handleItemUpdate = (id, updateData) => {
    onItemUpdate(id, updateData);
  };

  const handleEstimateUpdate = (id, updateData) => {
    onEstimateUpdate(id, updateData);
  };

  const handleItemDelete = id => {
    onItemDelete(id);
  };

  const handleMoveUp = id => {
    onMoveUp(id);
  };

  const handleMoveDown = id => {
    onMoveDown(id);
  };

  const standardDeviationMultipliers = {
    MEAN: 0,
    "68PC": 1,
    "90PC": 1.645,
    "95PC": 2,
  };

  const totalMean = () => {
    return Estimates.combinedMeans(items.map(i => i.estimates));
  };

  const totalStandardDeviation = () => {
    return Estimates.combinedStandardDeviations(items.map(i => i.estimates));
  };

  const totals = pointLevel => {
    return Estimates.totalEstimatesAtPoint(
      items.map(i => i.estimates),
      pointLevel
    );
  };

  const totalComputedInterval = () => {
    const standardDeviationMultiplier = standardDeviationMultipliers[confidenceLevel];
    return Estimates.totalCorrelatedConfidenceInterval(
      items.map(i => i.estimates),
      standardDeviationMultiplier
    );
  };

  const totalPertInterval = confidence => {
    const standardDeviationMultiplier = standardDeviationMultipliers[confidence];
    return Estimates.totalUncorrelatedConfidenceInterval(
      items.map(i => i.estimates),
      standardDeviationMultiplier
    );
  };

  return (
    <div>
      <table className="table is-bordered is-striped is-fullwidth">
        <thead>
          <tr>
            <th rowSpan={2}>Item</th>
            <th colSpan={3} className="estimates-table-estimates-heading">
              <span className="estimate-table-header-horizontal">
                <span>Estimates</span>
                <UnitsEditButton
                  units={units}
                  onUnitsChange={units => onUnitsChange(units)}
                />
              </span>
            </th>
            <th className="estimates-table-confidence-heading" colSpan={2}>
              Confidence Interval ({units})
              <br />
              <br />
              <ConfidenceDropDown
                confidenceLevel={confidenceLevel}
                onConfidenceChange={onConfidenceChange}
              />
            </th>
          </tr>
          <tr>
            <th className="estimates-table-small-column">Best</th>
            <th className="estimates-table-small-column">Likely</th>
            <th className="estimates-table-small-column">Worst</th>
            <th className="estimates-table-small-column">Min</th>
            <th className="estimates-table-small-column">Max</th>
          </tr>
        </thead>
        <tbody>
          {!loaded && (
            <tr>
              <td colSpan={5}>Loading...</td>
            </tr>
          )}
          {loaded &&
            items.map(item => (
              <Item
                key={item.id}
                item={item}
                confidenceLevel={confidenceLevel}
                onItemUpdate={handleItemUpdate}
                onEstimateUpdate={handleEstimateUpdate}
                onItemDelete={handleItemDelete}
                onMoveUp={handleMoveUp}
                onMoveDown={handleMoveDown}
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Totals</th>
            <th className="estimates-table-small-column">{totals("best")}</th>
            <th className="estimates-table-small-column">{totals("likely")}</th>
            <th className="estimates-table-small-column">{totals("worst")}</th>
            <th className="estimates-table-small-column">
              {totalComputedInterval()[0].toFixed(1)}
            </th>
            <th className="estimates-table-small-column">
              {totalComputedInterval()[1].toFixed(1)}
            </th>
          </tr>
        </tfoot>
      </table>
      <button className="button is-primary" onClick={handleItemAdd}>
        <span className="icon is-small">
          <i className="fas fa-plus-circle" />
        </span>
        <span>Add Item</span>
      </button>

      <br />
      <br />
      <br />
      <br />
      <h2 className="title is-4">PERT Analysis</h2>

      <div className="content">
        <p>
          Please note that the confidence interval in the totals row of the table above is
          simply the sum of the individual confidence intervals. This is a reasonable
          approximation if we assume the items being estimated are fully correlated, and
          that the estimators usually applied to a normal distribution can approximate
          values for PERT distribution confidence intervals.
        </p>
        <p>
          The tables below shows full PERT analysis confidence intervals which assumes no
          correlation between estimated items:
        </p>
      </div>

      <div className="columns">
        <div className="column">
          <table className="table is-bordered is-striped is-fullwidth">
            <tbody>
              <tr>
                <th>Mean</th>
                <td>{totalMean().toFixed(1)}</td>
              </tr>
              <tr>
                <th>Total Variance</th>
                <td>{Math.pow(totalStandardDeviation(), 2).toFixed(1)}</td>
              </tr>
              <tr>
                <th>Total Standard Deviation</th>
                <td>{totalStandardDeviation().toFixed(1)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column">
          <table className="table is-bordered is-striped is-fullwidth">
            <thead>
              <tr>
                <th rowSpan={2}>Confidence Level</th>
                <th colSpan={2}>Confidence Interval</th>
              </tr>
              <tr>
                <th>Min</th>
                <th>Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>68%</td>
                <td>{totalPertInterval("68PC")[0].toFixed(1)}</td>
                <td>{totalPertInterval("68PC")[1].toFixed(1)}</td>
              </tr>
              <tr>
                <td>90%</td>
                <td>{totalPertInterval("90PC")[0].toFixed(1)}</td>
                <td>{totalPertInterval("90PC")[1].toFixed(1)}</td>
              </tr>
              <tr>
                <td>95%</td>
                <td>{totalPertInterval("95PC")[0].toFixed(1)}</td>
                <td>{totalPertInterval("95PC")[1].toFixed(1)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ItemsTable;
