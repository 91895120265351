import React from "react";
import "./ItemsTable.scss";

class UnitsEditButton extends React.Component {
  constructor(props) {
    super(props);
    this.unitsInput = React.createRef();
    this.state = {editing: false};
  }

  handleClick() {
    this.setState({editing: true});
  }

  handleBlur() {
    this.setState({editing: false});
  }

  handleChange(e) {
    this.props.onUnitsChange(e.target.value);
  }

  componentDidUpdate() {
    if (this.state.editing) {
      this.unitsInput.current.focus();
    }
  }

  render() {
    return (
      <span>
        {!this.state.editing && (
          <button className="button" onClick={() => this.handleClick()}>
            <span className="has-text-weight-bold">({this.props.units})</span>
          </button>
        )}
        {this.state.editing && (
          <input
            className="input has-text-weight-bold"
            value={this.props.units}
            ref={this.unitsInput}
            onChange={e => this.handleChange(e)}
            onBlur={() => this.handleBlur()}
          />
        )}
      </span>
    );
  }
}

export default UnitsEditButton;
