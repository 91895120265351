import React from "react";
import {useHistory} from "react-router-dom";

function Home() {
  const history = useHistory();

  function handleStartButtonClick() {
    const availableChars = "abcdefghijklmnopqrstuvwxyz0123456789";
    const length = 15;
    let sheetId = "";
    for (let i = 0; i < length; i++) {
      sheetId += availableChars[Math.floor(Math.random() * availableChars.length)];
    }
    console.log(sheetId);
    history.push(`/sheet/${sheetId}`);
  }

  return (
    <div>
      <section className="hero is-dark is-bold is-medium">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-2">Three Point Estimator</h1>
            <div className="content">
              <p>
                Three point estimation is an excellent way of quantifying and managing
                estimate risk, especially where degrees of uncertainty exist.
              </p>
              <br />
              <div className="has-text-centered">
                <button
                  className="button is-primary is-large"
                  onClick={handleStartButtonClick}
                >
                  Start Now
                </button>
                <br />
                <br />
                <p>Get started immediately - no sign-up required!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="column content">
                <h3 className="title is-6">Easily Create Estimate Sheets</h3>
                <p>
                  Use our intuitive, spreadsheet like interface to create simple and clear
                  three-point estimates.
                </p>
                <p>
                  Create an estimates worksheet to manage estimates for a project, work
                  order, epic or SOW with ease.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="column content">
                <h3 className="title is-6">Collaborate With Your Team</h3>
                <p>
                  Leverage the power of your team, and let the people closest to the work
                  contribute effectively to your planning.
                </p>
                <p>
                  Our worksheets are fully collaborative and update in real-time. Great
                  for co-ordination via email, conference calling or remote teams.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="column content">
                <h3 className="title is-6">Crystal Clear Scoping</h3>
                <p>
                  Traditional spreadsheet cells are simply not designed for keeping track
                  of scoping notes.
                </p>
                <p>
                  Every item in our worksheets allows you to add a richly-formatted
                  description with bullet points, headings, links to external resources
                  and more.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="column content">
                <h3 className="title is-6">Explicitly Manage Risk</h3>
                <p>Quantitatively communicate and manage estimate risk with clarity.</p>
                <p>
                  We auto-calculate estimate totals for you at a percentage confidence
                  level you choose, putting you in the driving seat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
