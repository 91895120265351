import React from "react";

import "./ItemsTable.scss";
import {compose} from "redux";
import {connect} from "react-redux";
import {firebaseConnect, isLoaded} from "react-redux-firebase";
import ItemsTable from "./ItemsTable";

function mapStateToProps(state, {sheetId}) {
  const loaded = isLoaded(state.firebase.data.sheets);
  let items = [];

  const hasItems =
    loaded &&
    state.firebase.data.sheets &&
    state.firebase.data.sheets[sheetId] &&
    state.firebase.data.sheets[sheetId].items;

  if (hasItems) {
    items = state.firebase.data.sheets[sheetId].items;
    items = Object.entries(items).map(([key, value]) => ({...value, id: key}));
    items = items.sort((a, b) => a.orderingKey - b.orderingKey);
  }

  console.log("SHeeee", sheetId);

  return {
    items,
    loaded,
  };
}

const ItemsTableContainer = props => {
  const {firebase, sheetId, ...childProps} = props;

  const handleItemAdd = () => {
    const item = {
      title: "Untitled",
      estimates: {
        best: 0,
        likely: 0,
        worst: 0,
      },
      orderingKey: Date.now(),
    };
    firebase.push(`sheets/${sheetId}/items`, item);
  };

  const handleItemUpdate = (id, updateData) => {
    const db = firebase.database();
    db.ref(`sheets/${sheetId}/items/${id}`).update(updateData);
  };

  const handleEstimateUpdate = (id, updateData) => {
    const db = firebase.database();
    db.ref(`sheets/${sheetId}/items/${id}/estimates`).update(updateData);
  };

  const handleItemDelete = id => {
    const db = firebase.database();
    db.ref(`sheets/${sheetId}/items/${id}`).remove();
  };

  const handleMoveUp = id => {
    const db = firebase.database();

    const currentIndex = props.items.findIndex(item => item.id === id);
    if (currentIndex === 0) {
      return;
    }

    if (currentIndex === 1) {
      const newOrderingKey = props.items[0].orderingKey - 1000;
      db.ref(`sheets/${sheetId}/items/${id}`).update({orderingKey: newOrderingKey});
    } else {
      const firstOrderingKey = props.items[currentIndex - 2].orderingKey;
      const secondOrderingKey = props.items[currentIndex - 1].orderingKey;
      const newOrderingKey = (firstOrderingKey + secondOrderingKey) / 2;
      db.ref(`sheets/${sheetId}/items/${id}`).update({orderingKey: newOrderingKey});
    }
  };

  const handleMoveDown = id => {
    const db = firebase.database();

    const currentIndex = props.items.findIndex(item => item.id === id);
    const lastIndex = props.items.length - 1;
    if (currentIndex === lastIndex) {
      return;
    }

    if (currentIndex === lastIndex - 1) {
      const newOrderingKey = props.items[lastIndex].orderingKey + 1000;
      db.ref(`sheets/${sheetId}/items/${id}`).update({orderingKey: newOrderingKey});
    } else {
      const firstOrderingKey = props.items[currentIndex + 1].orderingKey;
      const secondOrderingKey = props.items[currentIndex + 2].orderingKey;
      const newOrderingKey = (firstOrderingKey + secondOrderingKey) / 2;
      db.ref(`sheets/${sheetId}/items/${id}`).update({orderingKey: newOrderingKey});
    }
  };

  return (
    <ItemsTable
      {...childProps}
      onItemAdd={handleItemAdd}
      onItemUpdate={handleItemUpdate}
      onItemDelete={handleItemDelete}
      onEstimateUpdate={handleEstimateUpdate}
      onMoveUp={handleMoveUp}
      onMoveDown={handleMoveDown}
    />
  );
};

export default compose(firebaseConnect(), connect(mapStateToProps))(ItemsTableContainer);
