import React from "react";
import ReactMarkdown from "react-markdown";

import "./ItemsTable/ItemsTable.scss";

class EditableMarkdownArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {editing: false, draft: props.text};
  }

  handleEditClick() {
    this.setState({editing: true});
  }

  handleDraftChange(e) {
    this.setState({draft: e.target.value});
  }

  handleCancelClick() {
    this.setState({editing: false, draft: this.props.text});
  }

  handleSaveClick() {
    this.props.onTextSaved(this.state.draft);
    this.setState({editing: false});
  }

  render() {
    return (
      <div className="content">
        {!this.state.editing && (
          <div>
            <ReactMarkdown source={this.props.text} />
            <button
              className="button is-outlined has-text-link is-small"
              onClick={() => this.handleEditClick()}
            >
              <span className="icon">
                <i className="far fa-edit" />
              </span>
              <span>Edit Description</span>
            </button>
          </div>
        )}
        {this.state.editing && (
          <div>
            <textarea
              className="textarea"
              value={this.state.draft}
              onChange={e => this.handleDraftChange(e)}
            />
            <br />
            <div className="field is-grouped is-pulled-right">
              <p className="control">
                <button
                  className="button is-small is-outlined is-success"
                  onClick={() => this.handleSaveClick()}
                >
                  <span className="icon">
                    <i className="fas fa-check" />
                  </span>
                  <span>Save</span>
                </button>
              </p>
              <p className="control">
                <button
                  className="button is-small is-outlined is-danger"
                  onClick={() => this.handleCancelClick()}
                >
                  <span className="icon">
                    <i className="fas fa-times" />
                  </span>
                  <span>Cancel</span>
                </button>
              </p>
            </div>
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default EditableMarkdownArea;
